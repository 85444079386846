<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.el-picker-panel {
  background: linear-gradient(0deg, #1d458d, rgba(11, 17, 40, 0.88));
  border: 1px solid rgba(89, 179, 255, 0.36);
}
.el-picker-panel input {
  background-color: transparent;
  border: 1px solid rgba(89, 179, 255, 0.36);
}
.el-picker-panel input:disabled {
  background-color: transparent !important;
  border: 1px solid rgba(89, 179, 255, 0.36);
}
 .el-picker-panel__footer button {
  background: transparent !important;
  background-color: transparent !important;
  border: none;
}
.el-picker-panel__footer button:disabled {
  background: transparent !important;
  background-color: transparent !important;
  border: none;
}
.el-picker-panel__footer {
  background: linear-gradient(0deg, #1d458d, rgba(11, 17, 40, 0.88));
  border: 1px solid rgba(89, 179, 255, 0.36);
}
.el-date-range-picker__content.is-left {
  border-right: 1px solid rgba(89, 179, 255, 0.36);
}
.el-date-range-picker__time-header {
  border-bottom: 1px solid rgba(89, 179, 255, 0.36);
}
.el-picker-panel {
  color: #c0c4cc;
}
.el-time-spinner__item.active:not(.disabled) {
  color: #c0c4cc;
}
.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: #606266;
}
.el-picker-panel__icon-btn,
.el-time-panel__btn {
  color: #c0c4cc;
}
.el-loading-mask {
  z-index: 9999 !important;
}
.el-dropdown-menu--mini, .el-message, .el-notification {
  z-index: 10000 !important;
}

</style>
