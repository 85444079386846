import Vue from 'vue'
import { getUserPermissions, getUserMenus } from '@/api/user'
import { asyncRoutes } from '@/router'
import { multArrayToOneArray } from '@/utils/util'
import {stringify} from "qs";
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
    } else {
        return true
    }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
    const res = []

    routes.forEach(route => {
        const tmp = {...route }
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles)
            }
            res.push(tmp)
        }
    })

    return res
}

const state = {
    menus: [],
    permissions: [],
    center: {}, //中心点经纬度
    zoom: {}, //图层缩放层级
    switch: {}, //图层层级
    retailPosition: null, //零售户定位
    chilMenus:[],
    // 最近颜色
    predefineColors:JSON.parse(localStorage.getItem('predefineColors'))||[]
}

const mutations = {
    SETPREDEFINECOLORS(state, color) {
        const predefineColors = state.predefineColors
        if(predefineColors.length===10){
            predefineColors.pop()
            predefineColors.unshift(color)
        }else{
            predefineColors.unshift(color)
        }
        localStorage.setItem('predefineColors',JSON.stringify(predefineColors))
        Vue.set(state, 'predefineColors', predefineColors)
    },
    SETMENUS(state, menus) {
        Vue.set(state, 'menus', menus)
    },
    SETCHILMENUS(state, menus) {
        localStorage.setItem('chilMenus',JSON,stringify(menus))
        Vue.set(state, 'chilMenus', menus)
    },
    SETPERMISSIONS(state, permissions) {
        state.permissions = permissions
    }
}

const actions = {
    async getMenus({ commit }) {
        let result = await getUserMenus()
        let menus = multArrayToOneArray(JSON.parse(JSON.stringify(result.data)), 'children')
        let routes = asyncRoutes.filter(route => {
            return menus.findIndex(item => item.path === route.path) >= 0
        })
        let permissions = menus.filter(item => {
            return item.permission
        })
        commit('SETMENUS', result.data)
        console.log('menus',result.data)
        permissions = [...permissions, ...state.permissions]
        commit('SETPERMISSIONS', permissions)
        return routes
    },
    async getPermissions({ commit }) {
        // console.log('fldjfldjfld')
        let result = await getUserPermissions()
        let permissions = [...result.data.permissions, ...state.permissions]
        // console.log('permissions:::::', permissions)
        commit('SETPERMISSIONS', permissions)
    }
}

// /**
//  * 多维数组转一维数组
//  * @param {*} array 数组
//  * @param {*} chiledFiled 子节点字段名
//  */
// function multArrayToOneArray(array, chiledFiled) {
//     array = JSON.parse(JSON.stringify(array))
//     let newArray = []
//     array.forEach(item => {
//         if(item[chiledFiled] && item[chiledFiled].length > 0) {
//             newArray.push(...multArrayToOneArray(item[chiledFiled], chiledFiled))
//             delete item[chiledFiled]
//         }
//         newArray.push(item)
//     })
//     return newArray
// }

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
