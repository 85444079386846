<template>
  <el-select
    v-model="selectValue"
    filterable
    placeholder="请选择"
    style="width: 100%"
    v-bind="$attrs"
  >
    <el-option
      v-for="item in options"
      :key="item[props.value]"
      :label="item[props.label]"
      :value="item[props.value]"
    >
    </el-option>
  </el-select>
</template>
<script>
export default{
  props:{
    value:{
      type:[Number,String],
      default:''
    },
    props:{
      type:Object,
      default:()=>{}
    },
    name:{
      type:String,
      default:''
    },
    options:{
      type:Array,
      default:()=>[]
    }
  },
  computed:{
    selectValue:{
      get(){
        return this.value
      },
      set(val){
        this.$emit('input',val)
        const name= this.options.find(item=>{
          return item[this.props.value]===val
        })[this.props.label]
        this.$emit('update:name',name)
      }
    }
  }
}
</script>
