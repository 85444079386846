
// 鉴权指令
import directiveAuth from '@/plugins/auth'

export default {
  async install(Vue) {
    // 指令
    Vue.directive('auth', directiveAuth)
  }
}
