import Vue from "vue";
import Router from "vue-router";
import Layout from "@/page/index/";
Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    component: Layout,
  },
  {
    // 登录页
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index"),
  },
  {
    // 数据大屏
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/index"),
  },
  // 帮助内容页
  {
    path: "/h/:id",
    component: () => import("@/views/help/contentBody.vue"),
    meta:{
      title:'help'
    }
  },
];

export const asyncRoutes = [

  {
    // 系统管理
    path: "/setting",
    component: Layout,
    redirect: "/setting/device/sys",
    children: [
      {
        path: "/setting/system",
        redirect: "/setting/system/userManagement",
      },
      {
        path: "/setting/system/articleManagement",
        name:'articleManagement',
        component: () => import("@/views/setting/articleManagement/index"),
      },
      {
        path: "/setting/system/alarmSetting",
        name:'AlarmSetting',
        component: () => import("@/views/setting/alarmSetting/index"),
      },
      {
        path: "/setting/system/systemSetting",
        name:'SystemSetting',
        component: () => import("@/views/setting/systemSetting/index"),
      },
      {
        path: "/setting/system/logManagement",
        name:'LogManagement',
        component: () => import("@/views/setting/logManagement/index"),
      },
      {
        path: "/setting/system/appSetting",
        name:'AppSetting',
        component: () => import("@/views/setting/appSetting/index"),
      },
      {
        path: "/setting/system/user",
        name:'UserManagement',
        component: () => import("@/views/permission/user/index"),
      },
      {
        path: "/setting/system/menuManagement",
        name:'MenuManagement',
        component: () => import("@/views/permission/menu/index"),
      },
      {
        path: "/setting/system/roleManagement",
        name:'RoleManagement',
        component: () => import("@/views/permission/role/index"),
      },
      {
        path: "/setting/system/orgManagement",
        name:'OrgManagement',
        component: () => import("@/views/permission/org/index"),
      },
      {
        path: "/setting/system/dictManagement",
        name:'DictManagement',
        component: () => import("@/views/permission/dict/index"),
      },
      // 电子设备
      {
        path:'/setting/application',
        redirect: "/setting/application/device/sys",
      },
/*      {
        path: "/setting/device",
        redirect: "/setting/rfid/warehouse",
      },
      {
        path: "/setting/device/sys",
        component: () => import("@/views/app/elec/elecManagement/index"),
      },
      {
        path: "/setting/device/punch",
        component: () => import("@/views/app/elec/clockInRecord/index"),
      },
      {
        path: "/setting/device/warning",
        component: () => import("@/views/app/elec/warningRecord/index"),
      },
      {
        path: "/setting/device/role",
        component: () => import("@/views/app/elec/role/index"),
      },
      {
        path: "/setting/device/user",
        component: () => import("@/views/app/elec/user/index"),
      },
      {
        path: "/setting/application/device/guardian",
        component: () => import("@/views/guardian/index"),
      },*/
      {
        path: "/setting/application/device/sys",
        component: () => import("@/views/app/elec/elecManagement/index"),
      },
      {
        path: "/setting/application/device/punch",
        component: () => import("@/views/app/elec/clockInRecord/index"),
      },
      {
        path: "/setting/application/device/warning",
        component: () => import("@/views/app/elec/warningRecord/index"),
      },
      {
        path: "/setting/application/device/role",
        component: () => import("@/views/app/elec/role/index"),
      },
      {
        path: "/setting/application/device/user",
        component: () => import("@/views/app/elec/user/index"),
      },
      {
        path: "/setting/application/device/work",
        component: () => import("@/views/app/elec/work/index"),
      },
      {
        path: "/setting/application/device/dangerous",
        component: () => import("@/views/app/elec/dangerous/index"),
      },
      {
        path: "/setting/application/device/guardian",
        component: () => import("@/views/guardian/index"),
      },
      {
        path: "/setting/application/device/guardian",
        component: () => import("@/views/guardian/index"),
      },
    ]
  },
  {
    // 便民服务
    path: "/service",
    component: Layout,
    redirect: "/service/disability",
    children: [
      // 残障人员监测
      {
        path: "/service/disability",
        component: () => import("@/views/device/index"),
      },
    ]
  },
  // 辅警管理
];

const createRouter = () =>
  new Router({
    routes: [...constantRoutes],
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;

// export default new Router({
//   routes: constantRoutes,
// })
