// /**
//  * @description 鉴权指令
//  * 当传入的权限当前用户没有时，会移除该组件
//  * 用例：<Tag v-auth="['admin']">text</Tag>
//  * */
 import store from '@/store'
 
 export default {
   inserted(el, binding) {
     const { value } = binding
     const access = store.getters.permissions
     if (value && value instanceof Array && value.length && access && access.length) {
       const isPermission = includeArray(value, access)
       if (!isPermission) {
         el.parentNode && el.parentNode.removeChild(el)
       }
     }
   }
 }

 function includeArray(list1, list2) {
    let status = false
    if (list1 === true) {
      return true
    } else {
      if (typeof list2 !== 'object') {
        return false
      }
      list2.forEach(item => {
        if (list1.includes(item)) status = true
      })
      return status
    }
  }
 