import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', false, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})

const getters = {
    menus: state => state.app.menus,
    permissions: state => state.app.permissions,
    center: state => state.app.center,
    zoom: state => state.app.zoom,
    switch: state => state.app.switch,
    chilMenus: state => state.app.chilMenus,
}

const store = new Vuex.Store({
    modules,
    getters
})

export default store
