// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

// dialog 拖拽
import './utils/directives.js'
// 导入 ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as filters from '@/utils/filters.js' // 全局filter
// 导入图标
// import 'vue-awesome/icons/flag' 迷你包
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'

import App from './App'
import router from './router'
import './styles/common.scss'

import '@/permission' // permission control
import "./mockjs/index.js"; // 导入mockjs

import Avue from '@smallwei/avue'
import '@smallwei/avue/lib/index.css'

//import '@/permission' // permission control
import "./mockjs/index.js"; // 导入mockjs

import vuescroll from 'vuescroll'

import echarts from 'echarts'

import store from './store'

// 插件
import plugins from '@/plugins'

import VueFullscreen from 'vue-fullscreen' // 全屏插件

// import '/public/leaflet/leaflet.css'
// 引入Leaflet对象 挂载到Vue上，便于全局使用，也可以单独页面中单独引用
// import L from "/public/leaflet/leaflet.js";
// import cascader from "./components/cascader/index.vue";
import treeSelect from "./components/treeSelect/index.vue";
// Vue.prototype.$L = L
import uploadImg from "./components/upload/uploadImg.vue";
import JSelect from "./components/j-select/index.vue";
Vue.component('uploadImg',uploadImg)
// Vue.component('cascader',cascader)
Vue.component('cascader',treeSelect)
Vue.component('JSelect',JSelect)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.$echarts = echarts

Vue.prototype.resetUrl = (url)=> {
  return `${process.env.VUE_APP_IMG_URL}${url}`
};

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.config.productionTip = false

// 安装 ElementUI
Vue.use(ElementUI);

Vue.use(Avue)

Vue.use(vuescroll);

Vue.use(VueFullscreen)

// 全局使用导入的图标
Vue.component('icon',Icon)

Vue.use(plugins)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  data: {
    Bus: new Vue()  // 空的实例放到根组件下，所有的子组件都能调用
  }
})
