<template>
  <div>
      <div class="header">
          <!-- 头部 -->
          <Header />
      </div>
      <div class="layout">
            <!-- 左侧导航菜单 -->
            <Menu />
            <!-- 主视图 -->
            <div class="main">
                <Main />
            </div>
      </div>
  </div>
</template>

<script>
import Header from './header.vue'
import Menu from './menu.vue'
import Main from './view.vue'
export default {
    components:{
        Header,
        Menu,
        Main
    }
}
</script>

<style lang="scss" scoped>
.layout{
    position: absolute;
    width: 100%;
    top: 48px;
    display: flex;
    height: calc(100vh - 48px);
}
.main{
    width: calc(100% - 101px);
    // height: calc(100vh - 58px);
    overflow: auto;
}
</style>