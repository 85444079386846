<template>
  <avue-input-tree
    v-model="selectValue"
    :node-click="getNodeData"
    :dic="options"
    :props="{
      label: 'name',
      value: 'id',
    }"
    :placeholder="placeholder"
    v-bind="$attrs"
    v-on="$listeners"
    style="width: 100%"
  />
</template>
<script>
export default {
  props: {
    placeholder:{
      type:String,
      default:'请选择'
    },
    options:{
      type:Array,
      default:()=>[]
    },
    value: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input',value)
      },
    },
  },
  methods:{
    getNodeData(item){
      this.$emit('valueChange',item)
      this.$emit('update:name',item.name)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .avue-input-tree{
  width: 100%;
}

</style>
