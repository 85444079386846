import router from './router'
import NProgress from 'nprogress' // progress bar
import store from './store'
import { getToken } from '@/utils/auth' // get token from cookie


NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    }else if (to.meta.title === 'help'){
      next()
      NProgress.done()
    } else {
      try {
        let { menus, permissions } = store.getters
        if (!permissions || permissions.length === 0) {
          // 获取权限
          await store.dispatch('app/getPermissions')
        }
        if (!menus || menus.length === 0) {
          // 获取菜单
         let accessRoutes =  await store.dispatch('app/getMenus')
          console.log(accessRoutes)
          router.addRoutes(accessRoutes)
          next({...to, replace: true})
          return
        }
        next()
      } catch (err) {
        console.error('err:', err)
      }
    }
  }else if(to.meta.title === 'help'){
    console.log(to.path)
    next()
    NProgress.done()
  } else {
    console.log(to)
    console.log('no token')
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
