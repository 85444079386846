import request from '@/utils/request'

export function putUserObj(data) {
  return request({
    url: '/admin/user',
    method: 'put',
    data
  })
}

export function delUserObj(id) {
  return request({
    url: `/admin/user/${id}`,
    method: 'delete',
  })
}

export function fetchUserList(data) {
  return request({
    url: '/admin/user/page',
    method: 'get',
    params: data
  })
}

export function addUserObj(data) {
  return request({
    url: '/admin/user',
    method: 'post',
    data
  })
}

export function userOut(token){
  return request({
    url: '/auth/token/'+token,
    method: 'delete'
  })
}

export function getUserPermissions() {
  return request({
    url: '/admin/user/info',
    method: 'get'
  })
}

export function getUserMenus() {
  return request({
    url: '/admin/menu',
    method: 'get'
  })
}

export function importUser(){
  return request({
    url: '/admin/user/importUser',
    method: 'post'
  })
}

export function downBlobFile(url,query, fileName) {
  return request({
    url: url,
    method: "get",
    responseType: "blob",
    params: query
  }).then(response => {
    // 处理返回的文件流

    /*const arrayBuffer = new ArrayBuffer(response.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < response.length; i++) {
      uint8Array[i] = response.charCodeAt(i);
    }*/
    const blob = response;
    if (blob && blob.size === 0) {
      this.$notify.error("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      window.URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}
