import request from '@/utils/request'

export function getAlarmTask() {
  return request({
    url: '/cms/public-param/getValues/Task',
    method: 'get',
  })
}

export function getAlarmMusic() {
  return request({
    url: '/cms/public-param/getValues/Music',
    method: 'get',
  })
}

export function setAlarm(data) {
  return request({
    url: '/cms/public-param/saveValues',
    method: 'post',
    data
  })
}

export function fetchLogList(params) {
  return request({
    url: '/admin/log/page',
    method: 'get',
    params
  })
}

export function getSysName() {
  return request({
    url: '/admin/param/selectSystemName',
    method: 'get',
  })
}

export function setSysName(params) {
  return request({
    url: '/admin/param/updateSystemName',
    method: 'post',
    params
  })
}
export function uploadImg(data){
  return request({
    url: '/admin/sys-file/uploadFile',
    method: 'post',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data
  })
}
export function queryVersion() {
  return request({
    url: '/cms/version/queryVersion',
    method: 'get',
  })
}

export function versionUpdate(query) {
  return request({
      url: '/cms/version/updateVersion',
      method: 'PUT',
      data: query
  })
}

