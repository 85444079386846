<template>
  <div class="menu">
    <el-scrollbar style="height: 100%">
      <ul>
        <li
          :class="{ active: menuActiveIndex === index }"
          v-for="(menu, index) in menuList"
          :key="index"
          @click="toPath(menu.url, index, menu)"
        >
          <p><img :src="menu.image" alt="" /></p>
          <p>
            <span>{{ menu.name }}</span>
          </p>
        </li>
      </ul>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      menuActiveIndex: 0,
    };
  },
  computed: {
    menuList: {
      get() {
        let menuList = [];
        const menus = this.$store.getters.menus;
        if (menus && menus.length > 0) {
          menuList = menus.map((item) => {
            // item.icon = item.icon.replace('@/', '')
            return {
              name: item.name,
              image: item.icon,
              url: item.path,
              children: item.children,
            };
          }).filter(item=>{
            console.log(item)
            return item.url==='/setting'||item.url==='/service'
          });
        }
        console.log(menuList)
        return menuList;
      },
    },
    // ...mapGetters(['menus'])
  },
  mounted() {
    this.initActive();
  },
  watch: {
    $route() {
      this.initActive();
    },
  },
  methods: {
    ...mapMutations({
      setChilMenus: "app/SETCHILMENUS",
    }),
    toPath(url, index, menu) {
      console.log(menu)
      const path = this.getPath([menu]).replace('/index', '')
      console.log(path)
      this.$router.push(path);
      this.menuActiveIndex = index;
      this.setChilMenus(menu.children);
    },
    getPath(data){
      if(data[0].children?.length>0){
        return this.getPath(data[0].children)
      }else{
        console.log(data[0].path)
        return data[0].path
      }
    },
    initActive() {
      let query = window.location.href
      this.menuList.forEach((item, index) => {
        if (query.indexOf("interior") !== -1) {
          query = "/interior";
        }
        if (query.indexOf("renovate") !== -1) {
          query = "/renovate";
        }
        if (query.indexOf("control") !== -1) {
          query = "/control";
        }
        if (query.indexOf("service") !== -1) {
          query = "/service";
        }
        if (query.indexOf("setting") !== -1) {
          query = "/setting";
        }
        if (query.includes(item.url)) {
          this.menuActiveIndex = index;
        }
      })
      /*let query = window.location.href;
      this.menuList.forEach((item, index) => {
        if (query.indexOf("vehicleBase") !== -1) {
          query = "/personBase";
        }
        if (query.indexOf("orgManagement") !== -1) {
          query = "/userManagement";
        }
        if (query.indexOf("roleManagement") !== -1) {
          query = "/userManagement";
        }
        if (query.indexOf("logManagement") !== -1) {
          query = "/setting/alarmSetting";
        }
        if (query.indexOf("imageSearchResult") !== -1) {
          query = "/imageSearch";
        }
        if (query.indexOf("/rfid/") !== -1) {
          query = "/rfid/itemmanage";
        }
        if (
          [
            "/rfidWarehouseManagement",
            "/rfidAssetTypeManagement",
            "/rfidDeviceManagement",
            "/rfidAccessSuperviseManage",
            "/rfidDesignatedRegulatoryManage",
          ].includes(query.split('#')[1])
        ) {
          query = "/rfidWarehouseManagement";
        }
        if (query.includes(item.url)) {
          this.menuActiveIndex = index;
        }
      });*/
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  width: 104px;
  z-index: 9998;
  margin: 0px;
  padding: 0px 0px 0px 7px;
  opacity: 0.9;
  background: #071a40;
  flex-shrink: 0;
  li {
    cursor: pointer;
    opacity: 0.5;
    width: 75px;
    min-height: 60px;
    font-size: 14px;
    padding-top: 10px;
    line-height: 25px;
    margin: 15px 10px;
    background: #042155;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #326394;
    svg {
      margin: auto;
      width: 100%;
    }
    span {
      width: 100%;
    }
  }
  li:hover {
    opacity: 1;
  }
  .active {
    opacity: 1;
  }
  ::v-deep .el-scrollbar {
    .el-scrollbar__wrap {
      overflow-x: visible;
    }
  }
}
</style>
