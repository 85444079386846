import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import { getToken, removeToken } from '@/utils/auth'
import { showLoading, hideLoading } from './loading'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

service.defaults.validateStatus = function(status) {
  return status >= 200 && status <= 500 // 默认的
}

// request interceptor
service.interceptors.request.use(
  config => {
    if(config.url.indexOf('/api/v1/stream') >= 0) {
      config.baseURL = '' // process.env.VUE_APP_ONLINE_VIDEO_URL
    }
    if (!config.hideLoading) {
      showLoading()
    }
    // do something before request is sent
    const token = getToken()
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    hideLoading()
    // do something with request error
    return Promise.reject(error)
  }
)
let resCount = 0
let resMess = true
// 响应拦截器
service.interceptors.response.use(response => {
  resCount = 0
  // 关闭加载窗口
  hideLoading()
  // 401 未登陆,10009, token失效
  if (response.status === 401) {
    if(resMess){
      Message({
        message: '登录超时，请重新登录',
        type: 'error',
        duration: 5 * 1000
      })
      resMess = false
    }
    removeToken()
    router.replace({ name: 'Login' })
    return Promise.reject(response.data.msg)
  }
  if (response.status === 500) {
    resMess = true
  }
  if(response.data.code===1){
    Message({
      message: response.data.msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(response.data.msg)
  }
  return response.data
}, error => {
  resCount++
  // 关闭加载窗口
  hideLoading()
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  })
  if (resCount > 3) {
    removeToken()
    router.replace({ name: 'Login' })
  }
  return Promise.reject(error)
})

export default service
