import * as CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'
import Vue from 'vue'

/**
 *加密处理
 */
export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key
  } = params
  const result = JSON.parse(JSON.stringify(data))
  if (type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(
        data,
        key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

/**
 * 时间戳转化为年 月 日 时 分 秒
 * number: 传入时间戳
 * format：返回格式，支持自定义，但参数必须与formateArr里保持一致
*/
export function formatTime(number, format) {
  var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
  var returnArr = [];
  number = Math.ceil(number)
  if(number.toString().length < 13) {
    number = number * 1000
  }
  var date = new Date(number);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1));
  returnArr.push(formatNumber(date.getDate()));

  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));

  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}

/**
 *数据转化
 */
function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}

/**
 * 换算比例坐标
 * ltx: 左上x坐标
 * lty: 左上y坐标
 * rbx: 右下x坐标
 * rby: 右下y坐标
 * ow: 原图宽度
 * oh: 原图高度
 * nw: 新图宽度
 * nh: 新图高度
 * format：返回格式，支持自定义，但参数必须与formateArr里保持一致
*/
export function getPictureMark(ltx, lty, rbx, rby, ow, oh, nw, nh) {
  const proportionW = nw / ow
  const proportionH = nh / oh
  return {
    width: parseInt(proportionW * rbx - proportionW * ltx) + 'px',
    height: parseInt(proportionH * rby - proportionH * lty) + 'px',
    top: parseInt(proportionH * lty) + 'px',
    left: parseInt(proportionW * ltx) + 'px'
  }
}

/**
 * 多维数组转一维数组
 * @param {*} array 数组
 * @param {*} chiledFiled 子节点字段名
 * @param {*} deleteChild 是否删除子节点
 */
export function multArrayToOneArray(array, chiledFiled, deleteChild = true) {
  // array = JSON.parse(JSON.stringify(array))
  let newArray = []
  array.forEach(item => {
    if (item[chiledFiled] && item[chiledFiled].length > 0) {
      newArray.push(...multArrayToOneArray(item[chiledFiled], chiledFiled, deleteChild))
      deleteChild && (delete item[chiledFiled])
    }
    newArray.push(item)
  })
  return newArray
}

/**
 * 获取所有子节点的下标
 * @param {*} array 
 * @param {*} chiledFiled 
 * @returns 
 */
export function getAllChildRenIndex(array, chiledFiled) {
  let newArray = []
  array.forEach((item, index) => {
    if (!item[chiledFiled] || item[chiledFiled].length === 0) {
      newArray.push([index])
    } else {
      let childArray = getAllChildRenIndex(item[chiledFiled], chiledFiled)
      childArray.forEach(row => {
        newArray.push([index, ...row])
      })
    }
  })
  return newArray
}

/**
 * 动态添加组件
 * @param {*} el 节点标识
 * @param {*} component 组件
 * @param {*} data 数据
 * @param {*} methods 方法
 */
export function dynamicComponent(el, component, data = {}, methods = {}) {
  const DynamicComp = Vue.extend(component)
  const dynamicComp = new DynamicComp({
    data,
    methods
  }).$mount()
  document.querySelector(el).appendChild(dynamicComp.$el)
}

/**
 * 跳转
 * @param {*} url 链接
 */
export function openUrl(to, from = null) {
  if(from){
    Cookies.set('history', from)
  }
  window.open(to,'_blank')
}