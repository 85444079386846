<template>
  <el-upload
    class="avatar-uploader"
    :http-request="uploadImage"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    action="#"
  >
    <img v-if="value" :src="value" class="avatar" alt="" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>
<script>
import { uploadImg } from "../../api/setting";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    async uploadImage(file) {
      const loading = this.$loading({
        lock: true,
        text: "文件上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const formData = new FormData()
      formData.append('file',file.file)
      const res = await uploadImg(formData).finally(()=>{
        loading.close()
      });
      return res
    },
    handleAvatarSuccess(res) {
      this.$emit('input',res)
    },
    beforeAvatarUpload(){
      console.log('执行1')
    }
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
