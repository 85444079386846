<template>
  <div class="top">
    <div class="link right">
      <img src="@/assets/header_date_icon.png" alt="" />
      {{ nowDate }}&nbsp;&nbsp;&nbsp;{{ nowTime }}&nbsp;&nbsp;&nbsp;{{
        nowWeek
      }}
    </div>
    <div class="title">e鹭守护</div>
    <div class="link left">
      <!--<router-link to="dashboard" target="_blank" v-if="showDashboard">
      <label style="cursor: pointer" class="data-screen-btn">
        <img
          src="@/assets/header_screen_icon.png"
          style="vertical-align: middle"
          alt=""
        />
        数据大屏
      </label>
      </router-link>-->
      <el-dropdown size="mini" @command="handleCommand">
        <span class="el-dropdown-link">
          <div class="line-alignment">
            <img
              src="@/assets/header_user_icon.png"
              alt=""
              style="margin-left: 40px"
            />
            <p @click="mouseenter" class="admin-name">{{ username }}</p>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-switch-button" command="out"
            >退出</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getCookie, removeToken, getToken, removeCookie } from '@/utils/auth'
import { getSysName } from "@/api/setting";
import { userOut } from '@/api/user'
export default {
  data() {
    return {
      nowDate: '', // 当前日期
      nowTime: '',
      nowWeek: '',
      username: null,
      display: false,
      showDashboard: false,
      title: ''
    }
  },
  mounted() {
    this.$root.Bus.$on("getTitle", () => {
      this.getTitle();
    });
    if(this.$route.path !== '/dashboard') {
      this.showDashboard = true
    }
    this.currentTime()
    this.username = getCookie('username')
    this.getTitle()
  },
  activated() {
    this.username = getCookie('username')
  },
  methods: {
    async getTitle() {
      let res = await getSysName()
      this.title = res.publicValue
    },
    //当前时间
    currentTime() {
      setInterval(this.formatDate, 500)
    },
    formatDate() {
      let date = new Date()
      let year = date.getFullYear() // 年
      let month = date.getMonth() + 1 // 月
      let day = date.getDate() // 日
      let week = date.getDay() // 星期
      let weekArr = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let hour = date.getHours() // 时
      hour = hour < 10 ? '0' + hour : hour // 如果只有一位，则前面补零
      let minute = date.getMinutes() // 分
      minute = minute < 10 ? '0' + minute : minute // 如果只有一位，则前面补零
      let second = date.getSeconds() // 秒
      second = second < 10 ? '0' + second : second // 如果只有一位，则前面补零
      this.nowDate = `${year}年${month}月${day}日`
      this.nowTime = `${hour}:${minute}:${second}`
      this.nowWeek = `${weekArr[week]}`
    },
    mouseenter() {
      this.display = !this.display
    },
    out() {
      userOut(getToken())
      removeToken()
      removeCookie('username')
      removeCookie('userId')
      removeCookie('deptId')
      removeCookie('deptName')
      this.$router.push('/login')
    },
    handleCommand(value) {
      if (value === 'out') {
        this.out()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.top {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 70px;
  line-height: 45px;
  top: 0px;
  background: url('../assets/top.png') no-repeat center;
  font-size: 0px;
  display: flex;
  justify-content: space-between;
  img {
    width: 35px;
    height: 35px;
  }
  .link {
    display: flex;
    width: 280px;
    align-items: center;
    font-size: 14px;
    height: 50px;
    a {
      color: #a3d6ff;
      text-decoration: none !important;
    }
  }
  .right {
    justify-content: flex-end;
  }
  .title {
    font-size: 30px !important;
    font-family: "Microsoft YaHei";
    font-weight: bold !important;
    background-image: linear-gradient(#e1ecf5 30%, #7bb6ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 24px;
    margin-right: 5px;
    letter-spacing: 2px;
    margin-top: 10px;
  }
  .admin-name {
    cursor: pointer;
    color: #a3d6ff;
  }
  .data-screen-btn {
    cursor: pointer img {
      vertical-align: middle;
    }
  }
  .menu {
    position: absolute;
    right: 15px;
    top: 45px;
    width: 100px;
    // height: 50px;
    background: #0c265a;
    text-align: center;
    .menu-line {
      cursor: pointer;
      line-height: 30px;
    }
    .menu-line:hover {
      background: rgba(19, 91, 176, 0.8);
    }
  }
}
</style>
